<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M23.364 7.632v10.384a2.366 2.366 0 0 1-2.374 2.359H7.5a2.366 2.366 0 0 1-2.374-2.359V7.632a2.366 2.366 0 0 1 2.374-2.359H20.99a2.366 2.366 0 0 1 2.374 2.359zm-1.75 0a.616.616 0 0 0-.624-.609H7.5a.616.616 0 0 0-.624.609v10.384c0 .335.277.609.624.609H20.99a.616.616 0 0 0 .624-.609V7.632z"
    />
    <path
      d="M12 12.958h4.5a.875.875 0 0 0 0-1.75H12a.875.875 0 1 0 0 1.75zM2.375 15.05V3.18c0-.334.277-.608.624-.608h14.99a.875.875 0 1 0 0-1.75H3A2.366 2.366 0 0 0 .625 3.18V15.05a.875.875 0 1 0 1.75 0z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
